// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@stimulus/polyfills";
import $ from 'jquery'
global.$ = $

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap"
import "../stylesheets/application"
import "@fortawesome/fontawesome-pro/css/all"

$(document).on('turbolinks:load', function() {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover({
    html: true,
    trigger: "hover"
  })

  // Navbar Search
  $('body, .navbar-collapse form[role="search"] button[type="reset"]').on('click keyup', function(event) {
    console.log(event.currentTarget);
    if (event.which == 27 && $('.navbar-collapse form[role="search"]').hasClass('active') ||
      $(event.currentTarget).attr('type') == 'reset') {
      closeSearch();
    }
  });

  function closeSearch() {
    const $form = $('.navbar-collapse form[role="search"].active')
    $form.find('input').val('');
    $form.removeClass('active');
  }

  // Show Search if form is not active // event.preventDefault() is important, this prevents the form from submitting
  $(document).on('click', '.navbar-collapse form[role="search"]:not(.active) button[type="submit"]', function(event) {
    event.preventDefault();
    const $form = $(this).closest('form'),
    $input = $form.find('input');
    $form.addClass('active');
    $input.focus();
  });

})



import "controllers"

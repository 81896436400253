import { Controller } from "stimulus"

export default class extends Controller {
  changed(event) {
    const district_id = event.target.value;
    $.ajax('/ui/subdistrict', {
      data: { district_id },
      dataType: 'script'
    });
  }
}
